<template>
    <div class="about row p-1">
        <vxe-grid ref="xGrid" v-bind="gridOptions">
            <template #option_btn="{ row }">
                <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                    <button type="button" :disabled="!canedit(row)" @click="edit(row)" class="btn btn-success">
                        <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button type="button" class="btn btn-warning d-none">Middle</button>
                    <!-- <button type="button" :disabled="!canedit(row)" @click="removeOne(row)" class="btn btn-danger">
                        <i class="fa-solid fa-trash-can"></i>
                    </button> -->
                </div>
            </template>
            <template #status_switch="{ row }">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" :data-id="row.id" :checked="row.status"
                        @change="change($event, row)" />
                </div>
            </template>
            <template #toolbar_buttons>
                <vxe-button @click="add" status="success">新增</vxe-button>
                <!-- <vxe-button @click="removeMany()">批量删除</vxe-button> -->
                <vxe-button @click="toStore">指派店铺</vxe-button>
                <vxe-button @click="toReStore">返修</vxe-button>
            </template>
        </vxe-grid>
        <vxe-modal v-model="selectStore" title="选择要指派的商家" :resize="true" destroy-on-close :maskClosable="false">
            <template #default>
                <vxe-form :data="storeData" title-align="right" @submit="submitToStore">
                    <vxe-form-item title="选择要指派的商家" title-align="left" :span="24"
                        :title-prefix="{ message: '只能指派给有资质的商家', icon: 'vxe-icon-info-circle-fill' }"></vxe-form-item>
                    <vxe-form-item field="sid" title="选择商家" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-select v-model="data.sid" :options="stores" transfer placeholder="请选选择一个商家">
                            </vxe-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item align="center" title-align="left" :span="24">
                        <template #default>
                            <vxe-button type="submit">提交</vxe-button>

                        </template>
                    </vxe-form-item>
                </vxe-form>
            </template>
        </vxe-modal>
        <vxe-modal v-model="showEdit" :title="selectRow ? '编辑&保存' : '新增&保存'" width="545" min-width="300" min-height="300"
            :loading="loadding" :resize="true" destroy-on-close @close="reset">
            <template #default>
                <vxe-form :data="formData" :rules="formRules" title-align="right" @submit="submitEvent">

                    <vxe-form-item title="Basic information" title-align="left" :span="24"
                        :title-prefix="{ icon: 'vxe-icon-comment' }"></vxe-form-item>
                    <vxe-form-item field="type" title="订单类型" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-select disabled v-model="data.type" :options="ordertypes" transfer placeholder="请选择订单类型">

                            </vxe-select>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="oid" title="订单号" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.oid" placeholder="请输入订单号"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="custom_name" title="客户名" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.custom_name" placeholder="请输入客户名"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="custom_phone" title="客户联系电话" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.custom_phone" placeholder="请输入客户联系电话"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item title="Other information" title-align="left" :title-width="200" :span="24" :title-prefix="{
                        message: '请填写必填项',
                        icon: 'vxe-icon-info-circle-fill',
                    }"></vxe-form-item>
                    <vxe-form-item field="content" title="订单内容" :span="24" :item-render="{}" :title-suffix="{
                        message: '订单内容必填',
                        icon: 'vxe-icon-question-circle-fill',
                    }">
                        <template #default="{ data }">
                            <vxe-textarea v-model="data.content" :autosize="{ minRows: 4, maxRows: 6 }">
                            </vxe-textarea>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item field="price" title="金额" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.price" placeholder="请输入金额" type="float"></vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item title="图片" title-align="left" :span="24">
                        <template #default>
                            <uploader @updateFilelist="updateFilelist" :filelist="formData.pic1"></uploader>
                        </template>
                    </vxe-form-item>

                    <vxe-form-item align="center" title-align="left" :span="24">
                        <template #default>
                            <vxe-button type="submit">提交</vxe-button>
                            <vxe-button @click="reset">重置</vxe-button>
                        </template>
                    </vxe-form-item>
                </vxe-form>
            </template>
        </vxe-modal>
    </div>
</template>
<script>
import uploader from '@/components/form/Uploader'
import {
    ordersAll,
    repositoryList,
    repositoryAdd,
    getAllWorker,
    getAllSotre,
    repositoryToStore,
    repositoryUpdate,
    repositoryDel,
    repositoryImport,
    restoreOrder
} from "@/requests/api";
import { VXETable } from "vxe-table";
import { ref } from 'vue'
const xGrid = ref()
const orderTypes = [
    "到店订单", "上门订单", "邮寄订单"
]

// const repair_state = [
//     "未指派",
//     "已指派"
// ]
// const statusTypes = [
//     '待服务',
//     '服务中',
//     '待支付',
//     '已完成',
//     '已取消',
//     '待退款',
//     '已退款'
// ]
// const statusObj = [
//     { label: '待服务', value: 0 },
//     { label: '服务中', value: 1 },
//     { label: '待支付', value: 2 },
//     { label: '已完成', value: 3 },
//     { label: '已取消', value: 4 },
//     { label: '待退款', value: 5 },
//     { label: '已退款', value: 6 }
// ]
const repairObj = [
    { label: '未指派', value: 0 },
    {
        label: '已指派', value: 1
    },
    {
        label: '全部', value: -1
    }
]
export default {
    name: "",
    created() {
        getAllSotre({ type: 2 }).then((res) => {
            this.stores = res
        })
        console.log(VXETable)
        console.log(this.$xGrid)
    },
    components: { uploader },
    updated() {

    },
    watch: {
        abc(value) {
            console.log(value)
        }
    },
    data() {
        return {
            selectRow: true,
            ordertypes: [{ label: '邮寄订单', value: 2 }],
            showEdit: false,
            selectStore: false,
            storeData: {
                sid: null
            },
            loadding: false,
            formData: {
                oid: '',
                type: 2,
                custom_name: "",
                content: "",
                custom_phone: "",
                price: 0,
                pic1: []
            },
            formRules: {
                name: [
                    { required: true, message: "请输入名称" },
                    { min: 2, max: 6, message: "长度在 2 到 6 个字符" },
                ],
            },
            gridOptions: {
                border: true,
                showHeaderOverflow: true,
                showOverflow: true,
                keepSource: true,
                id: "menu_list_table",
                height: 800,
                rowConfig: {
                    keyField: "id",
                    isHover: true,
                },
                columnConfig: {
                    resizable: true,
                },
                customConfig: {
                    storage: true,
                    // checkMethod({ column }) {
                    //     if (["create_time", "update_time"].includes(column.field)) {
                    //         return false;
                    //     }
                    //     return true;
                    // },
                },
                printConfig: {
                    // columns: [
                    //     { field: "name" },
                    //     { field: "path" },
                    //     { field: "link" },
                    //     { field: "pid" },
                    //     { field: "icon" },
                    //     { field: "status" },
                    //     { field: "order" },
                    //     { field: "desc" },
                    // ],
                },
                sortConfig: {
                    trigger: "cell",
                    remote: true,
                },
                filterConfig: {
                    remote: true,
                },
                pagerConfig: {
                    pageSize: 10,
                    pageSizes: [5, 10, 15, 20, 50, 100, 200, 500, 1000],
                },
                formConfig: {
                    titleWidth: 100,
                    titleAlign: "center",
                    items: [
                        {
                            field: "oid",
                            title: "订单号",
                            titlePrefix: {
                                message: "订单号不是id",
                                icon: "vxe-icon-question-circle-fill",
                            },
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入订单号" },
                            },
                        },
                        {
                            field: "custom_name",
                            title: "用户名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入用户名" },
                            },
                        },
                        {
                            field: "store_name",
                            title: "店铺名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入店铺名" },
                            },
                        },
                        {
                            field: "worker_name",
                            title: "技师名",
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入技师名" },
                            },
                        },
                        {
                            field: "start_time",
                            title: "开始日期",
                            folding: true,
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入开始日期", type: 'datetime' },
                            },
                        },
                        {
                            field: "end_time",
                            title: "结束日期",
                            folding: true,
                            itemRender: {
                                name: "$input",
                                props: { placeholder: "请输入结束日期", type: 'datetime' },
                            },
                        },
                        {
                            field: "repair_state",
                            title: "订单状态",
                            folding: true,
                            itemRender: {
                                name: "$select",
                                options: repairObj,
                                defaultValue: -1
                            },

                        },
                        {
                            align: "center",
                            collapseNode: true,
                            span: 24,
                            itemRender: {
                                name: "$buttons",
                                children: [
                                    {
                                        props: {
                                            type: "submit",
                                            content: "搜索",
                                            status: "primary",
                                        },
                                    },
                                    { props: { type: "reset", content: "清空" } },
                                ],
                            },
                        },
                    ],
                },
                toolbarConfig: {
                    slots: {
                        buttons: "toolbar_buttons",
                    },
                    refresh: true,
                    import: false,
                    export: false,
                    print: true,
                    zoom: false,
                    custom: true,
                },
                proxyConfig: {
                    seq: true, // 启用动态序号代理，每一页的序号会根据当前页数变化
                    sort: true, // 启用排序代理，当点击排序时会自动触发 query 行为
                    filter: true, // 启用筛选代理，当点击筛选时会自动触发 query 行为
                    form: true, // 启用表单代理，当点击表单提交按钮时会自动触发 reload 行为
                    // 对应响应结果 { result: [], page: { total: 100 } }
                    props: {
                        result: "data", // 配置响应结果列表字段
                        total: "total", // 配置响应结果总页数字段
                    },
                    // 只接收Promise，具体实现自由发挥
                    ajax: {
                        // 当点击工具栏查询按钮或者手动提交指令 query或reload 时会被触发
                        query: ({ page, sorts, filters, form }) => {
                            const queryParams = Object.assign({}, form);
                            // 处理排序条件
                            const firstSort = sorts[0];
                            if (firstSort) {
                                queryParams.sort = firstSort.field;
                                queryParams.order = firstSort.order;
                            }
                            // 处理筛选条件
                            filters.forEach(({ field, values }) => {
                                queryParams[field] = values.join(",");
                            });
                            queryParams.page = page.currentPage;
                            queryParams.limit = page.pageSize;
                            return repositoryList(queryParams);
                        },
                        queryAll: ({ filters, form }) => {
                            const queryParam = Object.assign({}, form);
                            filters.forEach(({ field, values }) => {
                                queryParam[field] = values.join(",");
                            });
                            return ordersAll(queryParam);
                        },
                        // 当点击工具栏删除按钮或者手动提交指令 delete 时会被触发
                        delete: ({ body }) => {
                            console.log(body);

                            return;
                        },
                        // 当点击工具栏保存按钮或者手动提交指令 save 时会被触发
                        save: ({ body }) => {
                            console.log(body);
                            return;
                        },
                        edit: ({ id }) => {
                            console.log(id);
                        },
                    },
                },
                columns: [
                    { type: "checkbox", title: "ID", width: 86 },
                    {
                        field: "oid",
                        sortable: false,
                        title: "订单号",
                    },
                    {
                        field: "type",
                        title: "类型",
                        sortable: false,
                        formatter({ cellValue }) {
                            return orderTypes[cellValue];
                        },
                    },
                    {
                        field: "custom_name",
                        title: "用户名",
                        width: 90,
                        sortable: false,
                        titlePrefix: {
                            useHTML: true,
                            content: "到店订单为用户的微信昵称，其他类型订单为导入时输入的用户名",
                        },
                    },
                    {
                        field: "store_name",
                        title: "指派店铺",
                        sortable: false,
                        titlePrefix: {
                            useHTML: true,
                            content: "到店订单为用户下单的店铺名，其他类型订单为手动指派的店铺名",
                        },
                    },
                    {
                        field: "worker_name",
                        title: "指派技师",
                        sortable: false,
                        titlePrefix: {
                            useHTML: true,
                            content: "到店订单为用户下单的技师名，其他类型订单为手动指派的技师名",
                        },
                    },
                    {
                        field: "content",
                        title: "订单详情",
                        sortable: false
                    },
                    {
                        field: "price",
                        title: "金额",
                        sortable: false
                    },
                    {
                        field: "custom_phone",
                        title: "电话",
                        sortable: false
                    },


                    // {
                    //     field: "status",
                    //     title: "订单状态",
                    //     sortable: false,
                    //     formatter({ cellValue }) {
                    //         return statusTypes[cellValue];
                    //     },
                    // },
                    {
                        field: "repair_state",
                        title: "维修状态",
                        formatter({ cellValue }) {
                            return cellValue == 0 ? '未指派' : '已指派';
                        },
                    },
                    {
                        field: "id",
                        title: "操作",
                        type: "html",
                        slots: {
                            default: "option_btn",
                        },
                    },

                ],
                importConfig: {
                    remote: true,
                    types: ["xlsx", "xls"],
                    modes: ['insert'],
                    // 自定义服务端导入
                    importMethod({ file, options }) {
                        console.log(options)
                        console.lol(xGrid)
                        let formBody = new FormData();

                        formBody.append("file", file);

                        return repositoryImport(formBody).then((res) => {
                            if (!res.num) {
                                VXETable.modal.message({
                                    content: "导入失败，请检查数据是否正确！",
                                    status: "error",
                                    top: 80,
                                });
                            } else {
                                VXETable.modal.message({
                                    content: `成功导入 ${res.num} 条记录！`,
                                    status: "success",
                                    top: 80,
                                });
                                // 导入完成，刷新表格
                                this.$refs.grid.commitProxy("query");
                            }


                        })

                    },
                },
                exportConfig: {
                    // remote: true,
                    // types: ["xls"],
                    // modes: ["current", "selected", "all"],
                    // 自定义服务端导出
                    // exportMethod({ options }) {
                    //     const $grid = xGrid.value;
                    //     const proxyInfo = $grid.getProxyInfo();
                    //     // 传给服务端的参数
                    //     const body = {
                    //         filename: options.filename,
                    //         sheetName: options.sheetName,
                    //         isHeader: options.isHeader,
                    //         original: options.original,
                    //         mode: options.mode,
                    //         pager: proxyInfo ? proxyInfo.pager : null,
                    //         ids:
                    //             options.mode === "selected"
                    //                 ? options.data.map((item) => item.id)
                    //                 : [],
                    //         fields: options.columns.map((column) => {
                    //             return {
                    //                 field: column.field,
                    //                 title: column.title,
                    //             };
                    //         }),
                    //     };
                    //     // 开始服务端导出
                    //     return fetch({ body })
                    //         .then((response) => response.json())
                    //         .then((data) => {
                    //             if (data.id) {
                    //                 VXETable.modal.message({
                    //                     content: "导出成功，开始下载",
                    //                     status: "success",
                    //                 });
                    //                 // 读取路径，请求文件
                    //                 fetch({}).then((response) => {
                    //                     response.blob().then((blob) => {
                    //                         // 开始下载
                    //                         VXETable.saveFile({
                    //                             filename: "导出数据",
                    //                             type: "xlsx",
                    //                             content: blob,
                    //                         });
                    //                     });
                    //                 });
                    //             }
                    //         })
                    //         .catch(() => {
                    //             VXETable.modal.message({
                    //                 content: "导出失败！",
                    //                 status: "error",
                    //             });
                    //         });
                    // },
                },
                checkboxConfig: {
                    labelField: "id",
                    reserve: true,
                    highlight: true,
                    range: true,
                    checkMethod: ({ row }) => {
                        return row.repair_state == 0
                    }
                },
            },
        };
    },
    methods: {

        changeStore(e) {
            if (e.value == "") {
                return
            }
            getAllWorker({ type: this.formData.type, sid: e.value }).then((res) => {
                this.workers = res
            })
        },
        add() {
            this.selectRow = false;
            this.showEdit = true;
        },
        edit(row) {
            this.selectRow = true;
            this.formData = row;
            this.showEdit = true;
            delete this.formData.create_time;
            delete this.formData.update_time;
        },
        removeOne(row) {
            VXETable.modal
                .confirm({
                    content: "删除后无法恢复！确定要删除？",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.notifyDelete([row.id]);
                        this.$refs.xGrid.remove(row);
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        removeMany() {
            if (this.$refs.xGrid.getCheckboxRecords().length == 0) {
                return
            }
            VXETable.modal
                .confirm({
                    content: "删除后无法恢复！确定要删除多条记录？",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.notifyDelete(
                            this.$refs.xGrid.getCheckboxRecords().map((x) => {
                                return x.id;
                            })
                        );
                        this.$refs.xGrid.removeCheckboxRow();
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        notifyDelete(ids) {
            repositoryDel({
                id: ids,
            }).then(() => {
                this.$refs.xGrid.commitProxy('query')
                VXETable.modal.message({
                    status: "success",
                    content: "操作成功！",
                    top: 80,
                });
            });
        },
        change(e, row) {
            console.log(e, row);
            // menuUpdate({
            //   id: row.id,
            //   status: e.target.checked ? 1 : 0,
            // })
            //   .then((res) => {
            //     console.log(res);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
        },
        reset() {
            var default_Data = {
                oid: '',
                type: 2,
                custom_name: "",
                content: "",
                custom_phone: "",
                price: 0,
                pic1: []
            };
            this.formData = default_Data;
        },
        submitEvent() {
            if (this.selectRow) {
                if (this.formData.pic1.length == 0) {
                    VXETable.modal.message({
                        status: "error",
                        content: "至少上传一张图片",
                        top: 80,
                    });
                    return
                }
                this.formData.pic1.forEach((item, index) => {
                    if (item.indexOf(this.$config.url) >= 0) {
                        this.formData.pic1[index] = item.split(this.$config.url)[1]
                    }
                })
                repositoryUpdate(this.formData).then(() => {
                    this.$refs.xGrid.reloadRow(this.formData);
                    this.showEdit = false;
                    VXETable.modal.message({
                        content: `更新成功`,
                        status: "success",
                    });
                    this.$refs.xGrid.commitProxy('query')
                });
            } else {
                if (this.formData.pic1.length == 0) {
                    VXETable.modal.message({
                        status: "error",
                        content: "至少上传一张图片",
                        top: 80,
                    });
                    return
                }
                repositoryAdd(this.formData)
                    .then(() => {
                        this.showEdit = false;
                        VXETable.modal.message({
                            content: `添加成功`,
                            status: "success",
                        });
                        this.$refs.xGrid.commitProxy('query')
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            this.reset();
        },
        visibleMethod() { },
        updateFilelist(value) {
            this.formData.pic1 = value
        },
        canedit(row) {
            if (row.repair_state != 0) {
                return false;
            }
            return true;
        },
        toStore() {
            if (this.$refs.xGrid.getCheckboxRecords().length == 0) {
                return
            }
            VXETable.modal
                .confirm({
                    content: "请确认订单信息无误，指派给商家后，将无法修改",
                })
                .then((res) => {
                    if (res == "confirm") {
                        this.selectStore = true;
                    } else {
                        VXETable.modal.message({
                            status: "info",
                            content: "操作取消",
                            top: 80,
                        });
                    }
                });
        },
        toReStore() {
            var verify = true
            let ids = this.$refs.xGrid.getCheckboxRecords().map((x) => {
                if (x.repair_state == 0) {
                    verify = false
                }
                return x.id;
            })
            if (!verify) {
                VXETable.modal.message({
                    status: "error",
                    content: "只能对已指派的订单进行此操作",
                });
                return
            }
            if (ids.length == 0) {
                VXETable.modal.message({
                    status: "error",
                    content: "必须选择一个订单",
                });
                return
            }
            restoreOrder({
                id: ids
            }).then(() => {
                VXETable.modal.message({
                    content: `操作成功`,
                    status: "success",
                });
            })
        },
        submitToStore() {

            if (this.storeData.sid) {
                let ids = this.$refs.xGrid.getCheckboxRecords().map((x) => {
                    return x.id;
                })
                repositoryToStore({
                    id: ids,
                    sid: this.storeData.sid
                }).then(() => {
                    VXETable.modal.message({
                        content: `操作成功`,
                        status: "success",
                    });
                    this.$refs.xGrid.commitProxy('query')
                    this.selectStore = false;
                }).catch(() => {
                    this.selectStore = false;
                })
            } else {
                VXETable.modal.message({
                    status: "info",
                    content: "必须选择一个商家",
                });
            }

        }

    },

};
</script>
<style>
.about {
    background: white;
}

/* .vxe-table--header {
          background-color: green;
          color: #fff;
        } */
</style>
  