<template>
    <div class="uploader">
        <div class="imgbox" @click="click" id="parent">
            <img class="addimg" id="add" src="../../assets/plus.png" alt="" title="点击选择图片" />
        </div>
        <vxe-modal v-model="isopen" show-zoom resize fullscreen>
            <template #default>
                <div style="width:100%;height:100%;text-align: center;">
                    <img :src="img" alt="" />
                </div>

            </template>
        </vxe-modal>
    </div>
</template>

<script>
import { VXETable } from 'vxe-table'
export default {
    name: 'sunny_uploader',
    data() {
        return {
            files: [],
            isopen: false,
            img: ""
        }
    },
    props: {
        maxNumber: {
            type: Number,
            default: 5
        },
        filelist: {
            type: Array,
            default() {
                return []
            }
        }
    },
    mounted() {
        console.log(this.filelist)
        if (this.filelist.length != 0) {
            this.filelist.forEach((item) => {
                item.indexOf(this.$config.url) < 0 ?
                    this.add(this.$config.url + item) :
                    this.add(item);
            })
        }
    },
    methods: {
        update() {
            this.$emit('updateFilelist', this.files)
        },
        click(e) {
            if (e.target.className == "addimg") {
                if (e.target.id == "add") {
                    if (this.files.length < this.maxNumber) { this.upload() } else {
                        VXETable.modal.message({
                            content: "上传文件数量超出最大限制",
                            status: "error",
                            top: 80,
                        });
                    }
                } else {
                    this.img = e.target.src
                    this.isopen = true
                }
            }
            if (e.target.className == "remove") {
                this.remove(e.target.parentNode)
            }
        },
        async upload() {
            try {
                const { status, files } = await VXETable.readFile({
                    multiple: false,
                    types: [
                        'jpeg', 'jpg', 'png'
                    ],
                    message: '选择要上传的图片'
                })
                if (status) {
                    Object.keys(files).forEach((item) => {
                        this.toBase64(files[item])
                    })
                } else {
                    VXETable.modal.message({
                        content: "文件格式不支持",
                        status: "error",
                        top: 80,
                    });
                }
            } catch (e) {
                console.log(e)
            }
        },
        add(path) {
            let parent = document.getElementById("parent")
            let btn = document.getElementById("add")
            let box = document.createElement("div")
            box.setAttribute("class", "ibox")
            let img = document.createElement('img')
            img.src = path
            img.setAttribute("class", "addimg")
            img.setAttribute("title", "点击放大")
            let span = document.createElement('span')
            span.innerText = "x"
            span.setAttribute("title", "点击移除")
            span.setAttribute("class", "remove");
            box.appendChild(img)
            box.appendChild(span)
            parent.insertBefore(box, btn)
            this.files.push(path)
            this.update()
        },
        remove(e) {
            e.parentNode.removeChild(e)
            this.files.splice(this.files.indexOf(e.childNodes[0].src), 1)
            this.update()
        },
        toBase64(file) {
            var that = this
            let reader = new FileReader();
            reader.onloadend = function () {
                that.add(reader.result)
            }
            reader.readAsDataURL(file);
        }
    }
}
</script>

<style>
.addimg {
    border-radius: 5px;
    height: 65px;
    width: 65px;
    box-shadow: -2px 3px 2px 2px rgba(0, 0, 0, .3);
    cursor: pointer;
}

.addimg:hover {
    border: 2px solid red;
    cursor: zoom-in;
}

#add {
    border: 0;
}

.ibox {
    display: inline-block;
    width: 65px;
    padding: 2px;
    margin: 0 10px 0 0;
}

.remove {
    position: relative;
    float: right;
    display: block;
    background: red;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    text-align: center;
    color: white;
    line-height: 15px;
    bottom: 73px;
    left: 10px;
    cursor: pointer;
}


#add:hover {
    cursor: pointer;
}
</style>